/* Run functions on document.ready */
$(document).ready(function(){
     /* toggle animation on hamburger menu */

    var $hamburger = $(".hamburger");

    $('#mobile-nav').on('show.uk.offcanvas', function () {
        $hamburger.toggleClass("is-active");
    });

     $('#mobile-nav').on('hide.uk.offcanvas', function () {
        $hamburger.toggleClass("is-active");
    });

       // Google map for Contact/Offices page
    if (typeof google === 'object' && typeof google.maps === 'object') {
        setGMap('contact-map', '.marker', 'ROADMAP', 15, '49.286344', '-123.123110');
    }

    var msg = 'Like looking at code? Is this your passion? Send us an email along with your resume and your github username to jobs+code@adnetinc.com';
    console.log(msg);


    /* Print button thingy */
    var $printButton = $('#print-button');
    $printButton.on('click', function(e){
        e.preventDefault();
        window.print();
    });

    $('#features .uk-panel h3').each(function(){
     var firstword = $(this);
     firstword.html(firstword.html().replace(/^(\w+)/, '<span>$1</span>'));
    });

    /* Format tables and lists */
    var mainDiv = $('#page-content');
    if (mainDiv.length){
        // Convert tables to uikit style table
        mainDiv.find('table').not('.exception').each(function(){
            var table = $(this);
            table.wrap('<div class="uk-overflow-container" />');
            table.addClass('uk-table uk-table-striped');
        });
    }

});

/* Run functions on window.load */
$(window).load(function(){

});
